import React from "react"
import logo from "./logo.svg"
import "./App.css"
import eliza from "./eliza_tristan.jpg"

function App() {
  return (
    <div className='App'>
      <header className='App-header'>
        <h1>IPNOPARTO</h1>
        <img src={eliza} className='App-logo' alt='logo' />
        <a>Coming soon...</a>
      </header>
    </div>
  )
}

export default App
